type FormatCurrencyProps = {
  value: number;
  locale: string;
  currency: string;
};
export const formatCurrency = ({
  value,
  locale,
  currency,
}: FormatCurrencyProps) =>
  new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);

export const isMdQuery = () => {
  return window.matchMedia('(max-width: 768px)').matches;
};
