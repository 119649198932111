import { IAirport, IFacility } from '@/types';

export function isNewRoutes(airport?: {
  airport_frienldy_url: string;
}): boolean {
  return !!airport?.airport_frienldy_url;
}

export function getParkingAtPath(airport: {
  airport_initials: string;
  airport_frienldy_url: string;
}): string {
  let urlpath = 'parkingat';
  if (isNewRoutes(airport)) {
    urlpath = `parking/${airport?.airport_frienldy_url}`;
  }
  return urlpath;
}

export function getUrlCodeOrSlug(item: {
  facility_url_code: string;
  facility_slug: string;
}) {
  if (item?.facility_slug) {
    return item?.facility_slug?.toLowerCase();
  }
  return item?.facility_url_code?.toLowerCase();
}
